// ChatGameContext.js
import { createContext, useContext, useState } from "react";

const ChatGameContext = createContext();

export const useChatGame = () => {
  return useContext(ChatGameContext);
};

export const ChatGameProvider = ({ children }) => {
  const [messages, setMessages] = useState({});

  const updateMessages = (characterId, newMessageThread) => {
    setMessages((previousMessageThread) => ({
      ...previousMessageThread,
      [characterId]: newMessageThread,
    }));
  };

  const value = {
    messages,
    updateMessages,
  };

  return (
    <ChatGameContext.Provider value={value}>
      {children}
    </ChatGameContext.Provider>
  );
};
