import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";

export const Footer = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const [showTooltip, setShowTooltip] = useState(false);
  const navigate = useNavigate();

  const handleSupport = () => {
    if (!isAuthenticated) {
      setShowTooltip(true);
      setTimeout(() => setShowTooltip(false), 3000); // Hide tooltip after 3 seconds
    } else {
      navigate("/support");
      // handle support click here
    }
  };

  return (
    <FooterContainer>
      <TooltipContainer>
        <MinorButton
          onClick={handleSupport}
          style={{ color: isAuthenticated ? "#069" : "gray" }}
        >
          Support
        </MinorButton>
        {showTooltip && <Tooltip>Please login to access support</Tooltip>}
      </TooltipContainer>
      <MinorButton
        onClick={() => (isAuthenticated ? logout() : loginWithRedirect())}
      >
        {isAuthenticated ? "Logout" : "Login / Sign Up"}
      </MinorButton>
      <MinorButton
        onClick={() => {
          window.open("https://twitter.com/josh_bickett", "_blank");
        }}
      >
        Follow on Twitter
      </MinorButton>
    </FooterContainer>
  );
};

const FooterContainer = styled("div")`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 35px 0;
  margin-top: 60px;
  font-size: 12px;
  gap: 15px;
`;

const MinorButton = styled("button")`
  background: none !important;
  border: none;
  padding: 0 !important;
  font-family: arial, sans-serif;
  color: #069;
  text-decoration: underline;
  cursor: pointer;

  text-decoration: none;
  font-size: 1em;
  &:hover {
    color: #1e88e5;
  }
`;
const TooltipContainer = styled("div")`
  position: relative;
  display: inline-block;
`;

const Tooltip = styled("div")`
  visibility: visible;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: -30px; // Updated
  left: 50%; // Updated
  transform: translateX(-50%); // Updated
  &:after {
    content: "";
    position: absolute;
    top: 100%; // Updated
    left: 50%; // Updated
    margin-left: -5px; // Updated
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
`;
