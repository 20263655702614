import styled from "@emotion/styled";
import { getCharacters } from "../utils/rss/gamesManager";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import { useState } from "react";

const characters = getCharacters();

export const Characters = ({ preview, navigateToCharacter }) => {
  const [characterList, setCharacterList] = useState(characters);
  const [showOverlay, setShowOverlay] = useState(false);
  const [currentShareLink, setCurrentShareLink] = useState("");
  const [copyText, setCopyText] = useState("Copy to clipboard");
  const [characterToCopy, setCharacterToCopy] = useState({});

  const showShareClicked = (id) => {
    setCurrentShareLink(`https://www.mysterygames.io/game/character/${id}`);
    setShowOverlay(true);
    setCharacterToCopy(
      characterList.filter((character) => character.id === id)[0]
    );
  };

  const copyLinkToClipboard = () => {
    navigator.clipboard
      .writeText(currentShareLink)
      .then(() => {
        console.log("Link copied to clipboard!");
      })
      .catch((error) => {
        console.error("Failed to copy link: ", error);
      });

    // after copied change the text to "Copied!"
    setCopyText("Copied!");
  };

  const closeOverlay = () => {
    setShowOverlay(false);
    setCopyText("Copy to clipboard");
    setCharacterToCopy({});
  };

  return (
    <Container>
      {showOverlay && (
        <Overlay>
          <OverlayContent>
            <h2 style={{ margin: 3, padding: 0 }}>{characterToCopy.name[0]}</h2>
            <h4>Share this link:</h4>

            <ShareInput
              type="text"
              value={currentShareLink}
              onChange={() => {}}
            />
            <ShareButton onClick={copyLinkToClipboard}>{copyText}</ShareButton>
            <CloseButton onClick={closeOverlay}>Close</CloseButton>
          </OverlayContent>
        </Overlay>
      )}

      {characterList.map((character, index) => {
        return (
          <CharacterContainer>
            {!preview && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <FlexContainer>
                    <ShareCardButton
                      onClick={() => navigateToCharacter(character.id)}
                    >
                      View
                    </ShareCardButton>
                  </FlexContainer>

                  <FlexContainer>
                    <ShareCardButton
                      onClick={() => showShareClicked(character.id)}
                    >
                      Share
                    </ShareCardButton>
                  </FlexContainer>

                  <FlexContainer>
                    <ChangeCircleOutlinedIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setCharacterList((prevCharacterList) => {
                          const updatedCharacterList = [...prevCharacterList]; // Create a copy of the character list
                          const updatedCharacter = {
                            ...updatedCharacterList[index],
                          }; // Create a copy of the character being clicked
                          updatedCharacter.activeImg =
                            updatedCharacter.activeImg === 0 ? 1 : 0; // Toggle the active image index
                          updatedCharacterList[index] = updatedCharacter; // Replace the original character with the updated one
                          return updatedCharacterList; // Return the updated character list
                        });
                      }}
                    />
                  </FlexContainer>
                </div>
              </div>
            )}
            <CharacterImage
              src={character.img[character.activeImg]}
              alt={`char${index}`}
              onClick={() => {
                setCharacterList((prevCharacterList) => {
                  const updatedCharacterList = [...prevCharacterList]; // Create a copy of the character list
                  const updatedCharacter = {
                    ...updatedCharacterList[index],
                  }; // Create a copy of the character being clicked
                  updatedCharacter.activeImg =
                    updatedCharacter.activeImg === 0 ? 1 : 0; // Toggle the active image index
                  updatedCharacterList[index] = updatedCharacter; // Replace the original character with the updated one
                  return updatedCharacterList; // Return the updated character list
                });
              }}
            />

            <InnerCharacterContainer>
              <h4 style={{ textAlign: "center", margin: 5 }}>
                {character.name[0]}
              </h4>
              <p style={{ textAlign: "center", margin: 0 }}>
                {character.description}
              </p>
            </InnerCharacterContainer>
            {!preview && character?.optional && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: 2,
                  color: "#808080",
                }}
              >
                (Optional Character)
              </div>
            )}
          </CharacterContainer>
        );
      })}
    </Container>
  );
};

const ShareCardButton = styled.button`
  background-color: #ffffff;
  border: 1px solid #000000;
  border-radius: 8px;
  padding: 5px;
  margin: 5px;
  width: 100%;
  cursor: pointer;
  user-select: none;
  &:hover {
    background-color: #000000;
    color: #ffffff;
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  padding: 5px;
  width: 100%;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const CharacterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 10px;
  width: 250px;
  min-height: 380px;
  @media (max-width: 768px) {
    width: 175px;
    min-height: 225px;
  }
`;

const InnerCharacterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;

const CharacterImage = styled.img`
  width: 200px;
  border-radius: 8px;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 150px;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const OverlayContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  gap: 10px;
  width: 400px;
`;

const ShareButton = styled.button`
  /* your button styles here */
  cursor: pointer;
  margin: 15px;
  font-size: 35px;
  padding: 10px 40px;
  background-color: #ececec;
  color: black;
  border-radius: 15px;
  user-select: none;
  border: none;
  &:hover {
    background-color: black;
    color: white;
  }
  &:active {
    scale: 1.1;
  }
`;

const CloseButton = styled.button`
  /* your button styles here */
  cursor: pointer;
  margin: 15px;
  font-size: 14px;
  width: 100px;
  background-color: #ececec;
  color: black;
  border-radius: 2px;
  user-select: none;
  border: none;
  &:hover {
    background-color: black;
    color: white;
  }
  &:active {
    scale: 1.1;
  }
`;

const ShareInput = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #000000;
  border-radius: 8px;
  font-size: 16px;
  outline: none;
`;
