import DoorOne from "../../images/door-1.jpg";
import DoorTwo from "../../images/door-2.jpg";
import DoorThree from "../../images/door-3.jpg";
import MansionCardOne from "../../images/mansion-card-1.jpeg";
import WesternCharacterOneA from "../../images/char-1a.jpeg";
import WesternCharacterOneB from "../../images/char-1b.jpeg";
import WesternCharacterTwoA from "../../images/char-2a.jpeg";
import WesternCharacterTwoB from "../../images/char-2b.jpeg";
import WesternCharacterThreeA from "../../images/char-3a.jpeg";
import WesternCharacterThreeB from "../../images/char-3b.jpeg";
import WesternCharacterFourA from "../../images/char-4a.jpeg";
import WesternCharacterFourB from "../../images/char-4b.jpeg";
import WesternCharacterFiveA from "../../images/char-5a.jpeg";
import WesternCharacterFiveB from "../../images/char-5b.jpeg";
import WesternCharacterSixA from "../../images/char-6a.jpeg";
import WesternCharacterSixB from "../../images/char-6b.jpeg";
import WesternCharacterSevenA from "../../images/char-7a.jpeg";
import WesternCharacterSevenB from "../../images/char-7b.jpeg";
import WesternCharacterEightA from "../../images/char-8a.jpeg";
import WesternCharacterEightB from "../../images/char-8b.jpeg";
import WesternCharacterNineA from "../../images/char-9a.jpeg";
import WesternCharacterNineB from "../../images/char-9b.jpeg";
import WesternCharacterTenA from "../../images/char-10a.jpeg";
import WesternCharacterTenB from "../../images/char-10b.jpeg";
import WesternCharacterElevenA from "../../images/char-11a.jpeg";
import WesternCharacterElevenB from "../../images/char-11b.jpeg";
import WesternCharacterTwelveA from "../../images/char-12a.jpeg";
import WesternCharacterTwelveB from "../../images/char-12b.jpeg";
import WesternCityImg from "../../images/city.jpeg";
import KrakenCoveImg from "../../images/pirates-2.jpeg";

import Key from "../../images/key.png";
import Barn from "../../images/barn.png";
import Map from "../../images/map.png";
import PotatoField from "../../images/potato-field.png";
import Xylazine from "../../images/xylazine.png";
import Ranch from "../../images/ranch.png";
import AlpacaFarm from "../../images/alpaca-farm.png";
import Saloon from "../../images/saloon.png";
import Mercantile from "../../images/mercantile.png";
import Blacksmith from "../../images/blacksmith.png";
import Knife from "../../images/knife.png";
import Offer from "../../images/offer.png";

// const gameArray = [
//   "Night at the Opera",
//   "An evening in 2142",
//   "Pirates game",
//   "1920s funnies",
// ];

export const getDoorImage = () => {
  const array = [DoorOne, DoorTwo, DoorThree];
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
};

export const getHeros = () => {
  return [
    {
      name: "Stealing Seconds in the Old West",
      img: MansionCardOne,
      subtext: "Try now!",
    },
    {
      name: "You get a role",
      img: WesternCharacterSixA,
      subtext: "Try to solve the mystery to win the game.",
    },
  ];
};

export const getCharacters = () => {
  return [
    {
      name: ["Professor Fate"],
      description: "The fortune teller",
      host: true,
      img: [WesternCharacterOneA, WesternCharacterOneB],
      activeImg: 0,
      id: "dfa66402-525b-4dd4-8246-0c7d5efcb3a9",
      shareable: [
        "Your fortune-telling powers allow your to answer 1 yes/no question from everyone here in town. The question can’t directly answer the who, what, or why of the mystery. ",
      ],
    },
    {
      name: ["Al Pacino", "Ally Pachino"],
      description: "Alpaca Farmer",
      img: [WesternCharacterTwoA, WesternCharacterTwoB],
      activeImg: 0,
      id: "025ec643-f66d-407f-a78a-efd9a9f6a19f",
      shareable: [
        "You are a former cattle rancher turned alpaca raiser.",
        "Several years ago you were able to retire early from cattle ranching after stumbling upon a gold nugget the size of a large potato while taking your horse to drink at the edge of a nearby river.",
        "Now you run an alpaca farm as a hobby and spin the finest alpaca wool in the West. You have donated your remaining fortunes to a horse rescue a few towns east.",
        "You are headed to the Winchester Mercantile to deliver some wool.",
      ],
      trust: [
        "Lately, you’ve heard some rumors from your neighbors at Rusty Spur Ranch that Bobbie the Cattle Rancher and Tex Ace the righthand man have been at odds lately over ranching practices.",
        "On your way into town, you saw a traveler in strange clothes appearing almost out of thin air near the woods.",
      ],
    },
    {
      name: ["Bobbie 'bull' Rider"],
      description: "Cattle Rancher at Rusty Spur Ranch",
      img: [WesternCharacterThreeA, WesternCharacterThreeB],
      activeImg: 0,
      id: "b7a32869-0579-4c2f-8f35-220fd5ad340e",
      shareable: [
        "You’re the most lucrative rancher in the region, and you believe the town is only as prosperous as it is because of your efforts.",
        "Much of the prestige of your ranch comes from your prized stallion, Bucky.",
        "You headed to the local Winchester Mercantile to gather some supplies with your righthand man on the ranch, Tex Ace.",
        "You’re debating if it was wise to leave the new cowboy, Silver Marshal, alone to watch over the ranch…oh well!",
      ],
      trust: [
        "You and Tex Ace have been at odds lately. You believed in a more hands-on approach, wanting to keep a close eye on the cattle. However, Tex was more of a free spirit, preferring to let the cattle roam and trusting in their instincts",
      ],
    },
    {
      name: ["Tex Ace"],
      description: "Cowboy, righthand man at Rusty Spur Ranch",
      img: [WesternCharacterFourA, WesternCharacterFourB],
      activeImg: 0,
      id: "cf65eadb-9879-4c4c-8884-82ec3669c560",
      shareable: [
        "You are the righthand man at Rusty Spur Ranch",
        "You have noticed your neighbor, Al Pacino the Alpaca Farmer, has been snooping around.",
        "You are training the new cowboy, Silver Marshal, and you left them in charge while you and Rancher Rusty Spur pick up supplies at the Winchester Mercantile today.",
      ],
      antagonist: true,
      trust: [
        "You’ve done it, don’t get caught!!!",
        "Who => You sedated and stole, Bucky, the once prized stallion from Rusty Spur Ranch.",
        "How => You used home made horse tranquilizer, to put Bucky to sleep, and moved him to a barn out of town until you can sell him.",
        "Why => To make money! You've reached out anonymous to a few ranches in towns around the state. There's some interest in buying a horse with Bucky's qualities. It sounds like you can get a pretty penny. Bucky's sale could set you up well to start over in another town with your own ranch where you can run it your way.",
        "You think you may have left the horse sedation in the barn and you are unable to find the Key to the barn. You are hoping townsfolk don’t notice. You are hoping to pin the horse disappearance on other suspicious townsfolk.",
        "You and Bobbie 'bull' Rider have been at odds lately. You believed in a free spirit approach, by letting the cattle roam and trusting in their instincts while Bobbie wants to keep a close eye on the cattle",
      ],
    },
    {
      name: ["Silver Marshal"],
      description: "New Cowhand at Rusty Spur Ranch",
      img: [WesternCharacterFiveA, WesternCharacterFiveB],
      activeImg: 0,
      id: "cf65eadb-9879-4c4c-8884-92jfoasei8",
      shareable: [
        "You’re the new Cowhand at Rusty Spur Ranch",
        "Cowboy Tex Ace has been training you. He’s complaining lately Bobbie 'bull' Rider stick approach of keeping a close eye on cattle and prefers to let the cattle roam and trust in their instincts.",
        "You were incharge of the ranch this morning while Rancher Rusty Spur and Cowboy Tex Ace went into town ",
      ],
      trust: [
        "You noticed your neighbor, Al Pacino the Alpaca Farmer, has been snooping around",
        "When you noticed Bucky went missing you ran to the mercantile so fast to tell Bobbie that you must have dropped your keys somewhere near the Potato fields. You drew up a map to try to find the keys, but you lost that too.",
      ],
    },
    {
      name: ["Wylie Clockwise"],
      description: "A mysterious traveler",
      img: [WesternCharacterSixA, WesternCharacterSixB],
      activeImg: 0,
      id: "5c2b919b-6549-480d-b0ab-bb86f6fb2f0c",
      shareable: [
        "You’re a traveler looking for a job.",
        "After a hefty donation to the horse rescue from  a local Alpaca Farm, you are able to purchase horses for rescue",
      ],
      trust: [
        "You traveled to 1875 because your great great great grandmother Rose Sagebrush was jailed in 1875. Based on evidence you’ve been collecting in your time travels, you are sure she is innocent. You came to prove her innocence but you are having second thoughts - what if this alters the future too much?! What if you’re never born?!",
        "You’re not sure you want anyone to know you are from the future for it could disrupt the space time continuum…. So you’re trying to blend in with the townspeople and find a job because you’re not sure how long you’ll be stuck here",
        "But first thing first - your time machine is powered by potatoes so you are headed to the mercantile to stock up on potatoes to refuel.",
      ],
    },
    {
      name: ["R. Winchester"],
      description: "Owner of Winchester Mercantile ",
      img: [WesternCharacterSevenA, WesternCharacterSevenB],
      activeImg: 0,
      id: "4f040247-2b21-4e62-bcac-6c1e17a89e36",
      shareable: [
        "You own the local mercantile and business is booming as the town is growing quickly",
      ],
      trust: [
        "You’ve been getting some strange special orders lately especially from… many visitors",
        "Wyatt Clockwise, a mysterious traveler who has been buying a lot of potatoes, more than anyone should ever need. Now you are out of potatoes. ",
        "Tex Ace, the cowboy at Rusty Spur Ranch who normally buys a lot of hay and carrots, but he's been buying some odd things lately.",
      ],
    },
    {
      name: ["Taylor Sagebrush"],
      description: "Reporter at Heart of the West Gazette",
      img: [WesternCharacterEightA, WesternCharacterEightB],
      activeImg: 0,
      id: "45aa3daa-8f19-4b35-907d-d1b60582621b",
      shareable: [
        "You are a Reporter at Heart of the West Gazette, the town newspaper ",
        "You clearly need the help - you have misplaced your journal that contains very important notes and story ideas",
        "Today you are heading to the Winchester Mercantile to buy a new journal and hope you run into some of the travelers to conduct some interviews for the paper",
      ],
      trust: [
        "You are writing a juicy tale to expose something fishy going on at Rusty Spur Ranch. ",
        "You were able to intercept a telegram from Rusty Spur Ranch, so you snuck into the barn at ranch in the wee hours of the morning to investigate. When you got there you didn’t see the prized horse you were hoping to photograph, so you’ll have to come back another time.",
        "While sneeking around you noticed a bright light near the Rusty Spur Ranch a few days ago and saw the traveler, Wyatt Clockwise also snooping around in the dark near there. ",
      ],
    },
    {
      name: ["Jesse Holliday"],
      description: "Rancher from another town",
      img: [WesternCharacterNineA, WesternCharacterNineB],
      activeImg: 0,
      id: "05290c63-678c-405c-9d3a-6d646b6d018a",
      shareable: [
        "You are a rancher from another town and you are looking to expand your business",
        "You are stopping through this town to see if you can find a good horse to purchase",
      ],
      trust: [
        "You received an annonomous message that there is a high quality horse to purchase in this town, but you hear a horse went missing recently so now you're wondering if something fishy is going.",
      ],
    },
    {
      name: ["Jesse James"],
      description: "Bartender at the local saloon",
      img: [WesternCharacterTenA, WesternCharacterTenB],
      activeImg: 0,
      id: "d2b03ad4-7c2e-4e0d-af62-1d15ce6ecb24",
      optional: true,
      shareable: [
        "You are the bartender at the local saloon and you hear all the town gossip",
        "You know everyone's favorite drink and can mix up a mean whiskey sour",
        "You overheard some strange conversations between Tex Ace and Silver Marshal the other night",
      ],
    },
    {
      name: ["Sheriff Hardlaw"],
      description: "Local County Sheriff",
      img: [WesternCharacterElevenA, WesternCharacterElevenB],
      activeImg: 0,
      id: "68112d53-738c-416c-9d3a-1d826b6d073b",
      optional: true,
      shareable: [
        "You are the new sheriff in town and it’s important to you to keep the peace, especially with curious passersby and newcomers to town like:",
        "One who seemed to almost appear out of thin air in the woods",
        "One who claims to be a fortune teller",
        "You usually chat with Rose Sagebrush, who writes the Rose Sagebrush local town newspaper, to compare notes on what is going on in town.",
      ],
      trust: [
        "You’re keeping your eyes peeled today because you saw someone sneaking around the potato fields.",
      ],
    },
    {
      name: ["Riley Grey"],
      description: "Owner of the local blacksmith shop",
      img: [WesternCharacterTwelveA, WesternCharacterTwelveB],
      activeImg: 0,
      id: "3f7a3df3-c8e7-4f58-a5d5-5e5aa4c7a52d",
      optional: true,
      shareable: [
        "You own the local blacksmith shop and are skilled in all things metalworking",
        "You've been receiving some odd requests for weapons and tools",
        "You've noticed some strangers in town buying large quantities of metal",
      ],
    },
  ];
};

export const getChatGameImgs = () => {
  const imgs = {
    western: WesternCityImg,
    treasure: KrakenCoveImg,
  };
  return imgs;
};

export const getChatCharacters = () => {
  return [
    {
      name: "Bobbie 'bull' Rider",
      description: "Cattle Rancher at Rusty Spur Ranch",
      img: [WesternCharacterThreeA, WesternCharacterThreeB],
      activeImg: 0,
      id: "b7a32869-0579-4c2f-8f35-220fd5ad340e",
    },
    {
      name: "Tex Ace",
      description: "Cowboy, righthand man at Rusty Spur Ranch",
      img: [WesternCharacterFourA, WesternCharacterFourB],
      activeImg: 0,
      id: "cf65eadb-9879-4c4c-8884-82ec3669c560",
      antagonist: true,
    },
    {
      name: "Silver Marshal",
      description: "New Cowhand at Rusty Spur Ranch",
      img: [WesternCharacterFiveA, WesternCharacterFiveB],
      activeImg: 0,
      id: "cf65eadb-9879-4c4c-8884-92jfoasei8",
    },
    {
      name: "Al Pacino",
      description: "Alpaca Farmer",
      img: [WesternCharacterTwoB, WesternCharacterTwoA],
      activeImg: 0,
      id: "025ec643-f66d-407f-a78a-efd9a9f6a19f",
    },
  ];
};

export const getGameProps = () => {
  return [
    {
      name: "Silver's Map",
      instruction:
        "This map includes a list of things. A barn, the merchantile, the saloon, the sawmill, the blacksmith shop, the potato fields, the Rusty Spur Ranch, and the Winchester Mercantile. ",
      location:
        "Place the map in plain sight somewhere in the game area. It will make the most sense to place it somewhere near the Winchester Mercantile.",
      img: Map,
    },
    {
      name: "Potato field",
      instruction:
        "This is where they Key to the Barn will be hidden. Print this potato field image off and put it somewhere around the location where the game is taking place. ",
      location: "",
      img: PotatoField,
    },
    {
      name: "Key to the Barn",
      instruction:
        "This key should be hidden in the 'the potato fields'. Put this key hidden somewhere near the potato field. Hide this key as well as you'd like. When the participants find it they will be allowed to open the Barn which has more clues ",
      img: Key,
    },
    {
      name: "The Barn",
      instruction:
        "Put this picture of the barn on something, say a door or a room and call this area the barn. Participants are not allowed into this space until they find the key to the barn. You can either physically lock this space until they have the key or you can just ask participants to not go in until they have the key and hope they abide by this rule. ",
      img: Barn,
    },
    {
      name: "Xylazine (Horse Tranquilizer)",
      instruction:
        "Put this picture somewhere in the barn. You may not need to hid it well. Up to you!",
      purpose:
        "HOW: This is a clue for how the horse was stolen - horse tranquilizer",
      img: Xylazine,
    },
    {
      name: "Knife",
      instruction: "",
      purpose: "WHO: This is a clue for who did it - Texas Ace",
      img: Knife,
    },
    {
      name: "The Offer",
      instruction:
        "The idea is that Tex Ace is making an offer to buy a Ranch in a neighboring town. Place this clue wherever you'd like, but we suggest leaving it at the Bar. ",
      purpose:
        "WHY: This is a clue for why Tex Ace did it - money. Specifically Tex Ace wants to buy a ranch from another town",
      img: Offer,
    },
    {
      name: "Rusty Spur Ranch",
      optional: true,
      img: Ranch,
    },
    {
      name: "Alpaca Farm",
      optional: true,
      img: AlpacaFarm,
    },
    {
      name: "Local saloon",
      optional: true,
      img: Saloon,
    },
    {
      name: "Winchester Mercantile",
      optional: true,
      img: Mercantile,
    },
    {
      name: "Blacksmith Shop",
      optional: true,
      img: Blacksmith,
    },
  ];
};

export const getTreasureChatCharacters = () => {
  return [
    {
      name: "Captain Bonnie 'Barracuda' Bay",
      description:
        "Master of the Salty Serpent, a pirate ship feared across the Seven Seas",
      img: [WesternCharacterThreeA, WesternCharacterThreeB],
      activeImg: 0,
      id: "a1b2c3d4-5678-9012-e345-678f90a1b2c3",
    },
    {
      name: "First Mate Fathom",
      description:
        "Bonnie's loyal right-hand man, known for his deep understanding of sea lore",
      img: [WesternCharacterThreeA, WesternCharacterThreeB],
      activeImg: 0,
      id: "b2c3d4e5-6789-0123-f456-789a1b2c3d4e",
      antagonist: true,
    },
    {
      name: "Crewman Calico Jack",
      description:
        "Newcomer to the Salty Serpent, Jack's charm hides a shrewd and ambitious mind",
      img: [WesternCharacterThreeA, WesternCharacterThreeB],
      activeImg: 0,
      id: "c3d4e5f6-7890-1234-g567-89a1b2c3d4e5",
    },
    {
      name: "Madam Mermaid Marina",
      description:
        "Enigmatic owner of the Kraken Cove Tavern, rumored to know more than she lets on",
      img: [WesternCharacterThreeA, WesternCharacterThreeB],
      activeImg: 0,
      id: "d4e5f678-9012-3456-h789-0a1b2c3d4e5f",
    },
  ];
};
