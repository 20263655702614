import styled from "@emotion/styled";
import { getChatCharacters } from "../utils/rss/gamesManager";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import { useState } from "react";
import { useChatGame } from "../ChatGameContext";

const characters = getChatCharacters();

export const ChatCharacters = ({ navigateToChat }) => {
  const [characterList, setCharacterList] = useState(characters);

  const { messages: allMessages } = useChatGame();

  return (
    <Container>
      {characterList.map((character, index) => {
        return (
          <CharacterContainer key={character + index}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100px",
              }}
            >
              <InnerCharacterContainer>
                <h4 style={{ textAlign: "center", margin: 5 }}>
                  {character.name}
                </h4>
                <p style={{ textAlign: "center", margin: 0 }}>
                  {character.description}
                </p>
              </InnerCharacterContainer>
            </div>

            <CharacterImage
              src={character.img[character.activeImg]}
              alt={`char${index}`}
              onClick={() => {
                setCharacterList((prevCharacterList) => {
                  const updatedCharacterList = [...prevCharacterList]; // Create a copy of the character list
                  const updatedCharacter = {
                    ...updatedCharacterList[index],
                  }; // Create a copy of the character being clicked
                  updatedCharacter.activeImg =
                    updatedCharacter.activeImg === 0 ? 1 : 0; // Toggle the active image index
                  updatedCharacterList[index] = updatedCharacter; // Replace the original character with the updated one
                  return updatedCharacterList; // Return the updated character list
                });
              }}
            />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 5fr 1fr",
              }}
            >
              <div></div>
              <FlexContainer>
                <ConvButton onClick={() => navigateToChat(character.id)}>
                  {allMessages[character.id] ? "Continue Chat" : "Start Chat"}
                </ConvButton>
              </FlexContainer>

              <FlexContainer>
                <ChangeCircleOutlinedIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setCharacterList((prevCharacterList) => {
                      const updatedCharacterList = [...prevCharacterList]; // Create a copy of the character list
                      const updatedCharacter = {
                        ...updatedCharacterList[index],
                      }; // Create a copy of the character being clicked
                      updatedCharacter.activeImg =
                        updatedCharacter.activeImg === 0 ? 1 : 0; // Toggle the active image index
                      updatedCharacterList[index] = updatedCharacter; // Replace the original character with the updated one
                      return updatedCharacterList; // Return the updated character list
                    });
                  }}
                />
              </FlexContainer>
            </div>
          </CharacterContainer>
        );
      })}
    </Container>
  );
};

const ConvButton = styled.button`
  background-color: #ffffff;
  border: 1px solid #000000;
  border-radius: 8px;
  padding: 5px;
  margin: 5px;
  width: 100%;
  cursor: pointer;
  user-select: none;
  &:hover {
    background-color: #000000;
    color: #ffffff;
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  padding: 5px;
  width: 100%;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const CharacterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 10px;
  width: 250px;
  min-height: 380px;
  @media (max-width: 768px) {
    width: 175px;
    min-height: 225px;
  }
`;

const InnerCharacterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;

const CharacterImage = styled.img`
  width: 200px;
  border-radius: 8px;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 150px;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const OverlayContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  gap: 10px;
  width: 400px;
`;

const ShareButton = styled.button`
  /* your button styles here */
  cursor: pointer;
  margin: 15px;
  font-size: 35px;
  padding: 10px 40px;
  background-color: #ececec;
  color: black;
  border-radius: 15px;
  user-select: none;
  border: none;
  &:hover {
    background-color: black;
    color: white;
  }
  &:active {
    scale: 1.1;
  }
`;

const CloseButton = styled.button`
  /* your button styles here */
  cursor: pointer;
  margin: 15px;
  font-size: 14px;
  width: 100px;
  background-color: #ececec;
  color: black;
  border-radius: 2px;
  user-select: none;
  border: none;
  &:hover {
    background-color: black;
    color: white;
  }
  &:active {
    scale: 1.1;
  }
`;

const ShareInput = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #000000;
  border-radius: 8px;
  font-size: 16px;
  outline: none;
`;
