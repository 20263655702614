import styled from "@emotion/styled";
import { useNavigate, useParams } from "react-router-dom";
import { getChatCharacters } from "./utils/rss/gamesManager";
import React from "react";
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
  AppBar,
  Toolbar,
} from "@mui/material";

const characters = getChatCharacters();
export const EndGame = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const goHome = () => {
    navigate("/bucky");
  };
  const selectedCharacter = characters.filter(
    (character) => character.id === id
  )[0];
  const isWinner = id === "cf65eadb-9879-4c4c-8884-82ec3669c560";
  const winningCharacter = characters.filter(
    (character) => character.id === "cf65eadb-9879-4c4c-8884-82ec3669c560"
  )[0];

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: 0,
          gap: 2,
          margin: 2,
        }}
      >
        {isWinner ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              maxWidth: "600px",
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              style={{ textAlign: "center" }}
            >
              Congratulations! You found who took Bucky!
            </Typography>
            <img
              src={winningCharacter.img[winningCharacter.activeImg]}
              alt={winningCharacter.name}
              style={{
                width: isSmallScreen ? 200 : 300,
                borderRadius: 10,
              }}
            />
            <h2 style={{ textAlign: "center", margin: 10, padding: 0 }}>
              {winningCharacter.name}
            </h2>
            <p style={{ textAlign: "center", margin: 10, padding: 0 }}>
              {winningCharacter.description}
            </p>
            <p style={{ textAlign: "center", margin: 10, padding: 0 }}>
              {" "}
              <b>How 👉</b> He used home made horse tranquilizer, to put Bucky
              to sleep, and moved him to a barn out of town until he could sell
              him.
            </p>
            <p style={{ textAlign: "center", margin: 10, padding: 0 }}>
              <b>Why 👉</b> To make money! He reached out anonymous to a few
              ranches in towns around the state. There was some interest in
              buying a horse with Bucky's qualities. It sounded like he could
              get a pretty penny. Bucky's sale could set him up well to start
              over in another town with his own ranch where he can run it his
              way.
            </p>

            <HomeButton onClick={goHome}>Go Home</HomeButton>
          </div>
        ) : (
          <div>
            <Typography
              variant="h4"
              gutterBottom
              style={{ textAlign: "center" }}
            >
              Oh no, you weren't able to save Bucky!
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: isSmallScreen ? "column" : "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  textAlign: "center",
                  gap: 2,
                  margin: isSmallScreen ? 4 : 0,
                }}
              >
                <Typography variant="h5">
                  {selectedCharacter.name} is innocent!
                </Typography>
                <img
                  src={selectedCharacter.img[selectedCharacter.activeImg]}
                  alt={selectedCharacter.name}
                  style={{
                    width: isSmallScreen ? 100 : 200,
                    borderRadius: 10,
                    margin: 10,
                  }}
                />
                <HomeButton onClick={goHome}>Try again?</HomeButton>
              </Box>
            </Box>
          </div>
        )}

        <Footer>
          <p style={{ paddingRight: 15 }}>
            <a href="https://twitter.com/josh_bickett">
              Follow me on Twitter to see more fun projects like this!
            </a>
          </p>
        </Footer>
        <div></div>
      </Box>
    </>
  );
};

const Footer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 10px;
  padding: 10px;
  font-size: 12px;
`;

const HomeButton = styled.div`
  background-color: #ffffff;
  border: 1px solid #000000;
  border-radius: 8px;
  padding: 5px;
  margin: 5px;
  min-width: 100px;
  cursor: pointer;
  user-select: none;
  text-align: center;
  &:hover {
    background-color: #000000;
    color: #ffffff;
  }
`;
