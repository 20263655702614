import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { getChatCharacters } from "./utils/rss/gamesManager";
import { BuckyChatInterface } from "./components/BuckyChatInterface";

const characters = getChatCharacters();

export const BuckyPage = () => {
  const { id } = useParams();
  const character = characters.filter((character) => character.id === id)[0];

  // show the character name by getting the character with the id from param
  if (!character) return <div>Character not found</div>;
  else
    return <BuckyChatInterface character={character} characters={characters} />;
};
