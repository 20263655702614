import { CSSTransition } from "react-transition-group";

import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import PlayingCardImage from "./images/playing-card-1.jpg";
import { getChatGameImgs } from "./utils/rss/gamesManager";
const gameImages = getChatGameImgs();

export const GameOptions = ({ setBuckyEntered, setTreasureEntered }) => {
  const [isBuckyFlipped, setIsBuckyFlipped] = useState(false);
  const [isTreasureFlipped, setIsTreasureFlipped] = useState(false);

  const handleBuckyFlip = () => {
    setIsBuckyFlipped(!isBuckyFlipped);
  };
  const handleTreasureFlip = () => {
    setIsTreasureFlipped(!isTreasureFlipped);
  };

  useEffect(() => {
    if (isBuckyFlipped) {
      setTimeout(() => {
        setBuckyEntered(true);
      }, [1000]);
    }
  }, [isBuckyFlipped, setBuckyEntered]);

  useEffect(() => {
    if (isTreasureFlipped) {
      setTimeout(() => {
        setTreasureEntered(true);
      }, [1000]);
    }
  }, [isTreasureFlipped, setTreasureEntered]);

  return (
    <Container>
      <div style={{ margin: 30 }}>
        <h1 style={{ padding: 0, margin: "10px 0", textAlign: "center" }}>
          For Sale, reach out to josh@bickett.net if interested!
        </h1>
        {/* <p style={{ textAlign: "center", margin: "0 10px" }}>
          Play detective and figure it out!
        </p> */}
      </div>

    </Container>
  );
};

const Container = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: black;
  text-align: center;
`;

const PlayingCard = styled.img`
  width: 250px;

  border-radius: 16px;
`;

const PlayingCardStatic = styled.img`
  width: 200px;

  border-radius: 16px;
`;

const GameOptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  gap: 25px;
  padding: 10px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
