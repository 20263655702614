import styled from "@emotion/styled";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useState, useRef, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import SendIcon from "@mui/icons-material/Send";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import ButtonBase from "@mui/material/ButtonBase";
import { useChatGame } from "../ChatGameContext";
import Close from "@mui/icons-material/Close";
import {
  // Other imports
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { getChatCharacters } from "../utils/rss/gamesManager";
import { useAuth0 } from "@auth0/auth0-react";

export const BuckyChatInterface = ({ character, characters }) => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/bucky");
  };

  const navigateToChat = (id) => {
    // log the id to the console
    // navigate to /game/character with the id in the get params
    navigate(`/bucky/character/${id}`);
    // force rerender
    setCharacterInfo(characters.filter((character) => character.id === id)[0]);
  };

  const { messages: allMessages, updateMessages } = useChatGame();
  const messages = allMessages[character.id] || [];

  const [inputValue, setInputValue] = useState("");
  const [requestCount, setRequestCount] = useState(0);
  const [requestLimit, setRequestLimit] = useState(25);
  const maxLength = 500; // Set the maximum character limit

  const handleInputChange = (event) => {
    if (event.target.value.length <= maxLength) {
      setInputValue(event.target.value);
    }
  };
  const bottomChatRef = useRef(null);
  useEffect(() => {
    bottomChatRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const [isRateLimited, setIsRateLimited] = useState(false);
  const [hasCopied, setHasCopied] = useState(false);

  const handleSendMessage = (event) => {
    event.preventDefault();

    const humanMessage = { text: inputValue, sender: "human" };
    const newMessages = [...messages, humanMessage];

    updateMessages(character.id, newMessages);

    setInputValue("");
    //
    const url = "https://mysteries-backend.herokuapp.com/chat";
    // const url = "http://127.0.0.1:5000/chat";

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        conversation: newMessages,
        characterName: character.name,
        secret:
          "3b3c2eeca13644c01984fef087bfdb756b24e0e18e39c011c60784ce4ba91fc9",
        hasCopied: hasCopied,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error && data.rate_limit) {
          setIsRateLimited(true);
        } else {
          const aiMessage = { text: data.response, sender: "ai" };
          const updatedMessages = [...newMessages, aiMessage];
          updateMessages(character.id, updatedMessages);
          if (data.request_count && data.request_count) {
            setRequestCount(data.request_count);
            setRequestLimit(data.request_limit);
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const [characterInfo, setCharacterInfo] = useState(character);

  const [isOpen, setIsOpen] = useState(false);
  const [sharing, setSharing] = useState(false);
  const shareWithFriend = () => {
    setIsRateLimited(true);
    setSharing(true);
  };

  const handleDrawerToggle = () => {
    setIsOpen(!isOpen);
  };

  const isMobile = window.innerWidth < 768;

  const handleCopyInviteLink = () => {
    const inviteLink = "https://www.mysteries.ai/bucky";
    navigator.clipboard.writeText(inviteLink);
    setHasCopied(true);
    setIsRateLimited(false);
    setRequestLimit(100);
  };

  const mobileInputContainerStyles = {
    position: "fixed",
    bottom: 0,
    backgroundColor: "#fff",
    width: "90%",
  };

  return (
    <Container>
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <RateLimitModal
            open={isRateLimited}
            handleClose={() => {
              // we'll just go ahead and give them 50 more messages even if they close
              setHasCopied(true);
              setIsRateLimited(false);
              setRequestLimit(100);
              setSharing(false);
            }}
            handleCopy={handleCopyInviteLink}
            requestLimit={requestLimit}
            sharing={sharing}
          />
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
            style={{ margin: 25 }}
          >
            <MenuIcon />
          </IconButton>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              width: 400,
              margin: 10,
              gap: 10,
            }}
          >
            {requestCount > 0 && requestLimit > 0 && (
              <div>
                <p>
                  {requestCount} / {requestLimit} messages
                </p>
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <BackButtonContainer onClick={goBack}>Back</BackButtonContainer>
            </div>
          </div>
        </div>
        <Drawer anchor="left" open={isOpen} onClose={handleDrawerToggle}>
          <Box
            sx={{
              width: 250,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Close onClick={handleDrawerToggle} style={{ margin: 15 }} />
            </div>

            <h3>Conversations</h3>
            {characters.map((mapCharacter, index) => {
              return (
                <div key={mapCharacter + index}>
                  <h6
                    onClick={() => navigateToChat(mapCharacter.id)}
                    style={{ margin: 0, padding: 0, cursor: "pointer" }}
                  >
                    {mapCharacter.name}
                  </h6>
                  <ButtonBase onClick={() => navigateToChat(mapCharacter.id)}>
                    <img
                      src={mapCharacter.img[mapCharacter.activeImg]}
                      alt="Image1"
                      style={{ height: 100, margin: 10 }}
                    />
                  </ButtonBase>
                </div>
              );
            })}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <BackButtonContainer onClick={goBack}>
                Game Home
              </BackButtonContainer>
            </div>
          </Box>
        </Drawer>
      </div>
      <OverviewContainer style={{ marginTop: isMobile ? 0 : 25 }}>
        <HeaderContainer>
          <CharacterDetailContainer>
            <CharacterTextContainer>
              <Header>{characterInfo.name}</Header>
              <SubHeader>{characterInfo.description}</SubHeader>
            </CharacterTextContainer>

            <CharacterImg
              src={characterInfo.img[characterInfo.activeImg]}
              alt={characterInfo.name}
              onClick={() => {
                setCharacterInfo((prevCharacterInfo) => {
                  const updatedCharacterInfo = { ...prevCharacterInfo };

                  updatedCharacterInfo.activeImg =
                    updatedCharacterInfo.activeImg === 0 ? 1 : 0;
                  return updatedCharacterInfo;
                });
              }}
            />
          </CharacterDetailContainer>
        </HeaderContainer>

        <Box
          style={
            isMobile
              ? mobileInputContainerStyles
              : { width: "100%", maxWidth: 500, p: 2 }
          }
        >
          <Paper elevation={3} sx={{ height: 380, overflow: "auto" }}>
            <List>
              {messages.map((message, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={message.text}
                    align={message.sender === "ai" ? "right" : "left"}
                  />
                </ListItem>
              ))}
              <div ref={bottomChatRef} />
            </List>
          </Paper>
          <form onSubmit={handleSendMessage}>
            <TextField
              fullWidth
              margin="normal"
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Type your message here"
              maxLength={maxLength}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleSendMessage} edge="end">
                      <SendIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <MiniText>
                {inputValue.length}/{maxLength}
              </MiniText>
              <MiniText style={{ cursor: "pointer" }} onClick={shareWithFriend}>
                Share with a friend
              </MiniText>
            </div>
          </form>
        </Box>
      </OverviewContainer>
    </Container>
  );
};

const RateLimitModal = ({
  open,
  handleClose,
  handleCopy,
  requestLimit,
  sharing,
}) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <RateLimitContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <Close
            onClick={handleClose}
            style={{ margin: 5, cursor: "pointer" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <DialogTitle style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}>
            {sharing ? "Share with a friend" : "Message Cap Reached, Partner"}
          </DialogTitle>
        </div>

        <div style={{ margin: "0 25px" }}>
          {requestLimit < 101 ? (
            <div>
              <DialogContentText style={{ margin: "10px 0" }}>
                {sharing
                  ? "Here's the link partner."
                  : "To rustle up more messages, kindly take the invite link below and share it with a friend, partner."}
              </DialogContentText>
              <DialogContentText
                style={{ margin: "10px 0" }}
              ></DialogContentText>

              <TextField
                autoFocus
                margin="dense"
                label="Invite Link"
                value="https://www.mysteries.ai/bucky"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
              <DialogActions>
                <ShareButton onClick={handleCopy}>Copy Invite Link</ShareButton>
              </DialogActions>
            </div>
          ) : (
            <DialogContentText style={{ margin: "10px 0 25px 0" }}>
              Looks like there are no more messages for you partner. You've used
              all 100 of them.
            </DialogContentText>
          )}
        </div>
      </RateLimitContainer>
    </Dialog>
  );
};

const RateLimitContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  width: 500px;
  @media (max-width: 500px) {
    width: 300px;
  }
`;

const MiniText = styled.div`
  font-size: 12px;
  color: #000000;
  margin: 5px;
`;

const ShareButton = styled.div`
  background-color: #ffffff;
  border: 1px solid #000000;
  border-radius: 8px;
  padding: 5px;
  margin: 5px;
  min-width: 100px;
  cursor: pointer;
  user-select: none;
  text-align: center;
  &:hover {
    background-color: #000000;
    color: #ffffff;
  }
`;

const BackButtonContainer = styled.div`
  background-color: #ffffff;
  border: 1px solid #000000;
  border-radius: 8px;
  padding: 5px;
  margin: 5px;
  min-width: 75px;
  cursor: pointer;
  user-select: none;
  text-align: center;
  &:hover {
    background-color: #000000;
    color: #ffffff;
  }
`;

const Container = styled.div`
  min-height: 100vh;
`;

const OverviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  padding: 10px;
  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
  }
`;

const CharacterTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
    max-width: 250px;
  }
`;

const CharacterDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  @media (max-width: 768px) {
    flex-direction: row;
  }
`;

const Header = styled.h1`
  font-size: 24px;
  margin: 0;
  padding: 0;
`;

const SubHeader = styled.p`
  margin: 5px;
  text-align: center;
`;

const CharacterImg = styled.img`
  height: 300px;
  border-radius: 16px;
  @media (max-width: 768px) {
    height: 200px;
    width: auto;
  }
`;

const SubText = styled.p`
  font-size: 14px;
  padding: 0;
  margin: 0 5px 5px 5px;
`;

const Information = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  max-width: 400px;
  background-color: #ececec;
  border-radius: 16px;
  padding: 20px;
`;
