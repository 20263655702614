import styled from "@emotion/styled";

import { getGameProps } from "../utils/rss/gamesManager";

const props = getGameProps();

export const GameProps = ({ preview, navigateToCharacter }) => {
  const startPrint = async (imageUrl) => {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);

    const printWindow = window.open(blobUrl, "_blank");
    printWindow.document.close();
    printWindow.focus();
    printWindow.addEventListener("load", () => {
      printWindow.print();
      setTimeout(() => {
        printWindow.close();
        URL.revokeObjectURL(blobUrl);
      }, 1000);
    });
  };

  return (
    <Container>
      <h1>Game Props</h1>
      <p>
        Print of the required game props below. Follow the instructions to
        ensure the players have a good chance of solving the mystery.{" "}
      </p>
      <PropContainer>
        {props.map((prop, index) => {
          return (
            <OuterPropContainer>
              {!preview && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <FlexContainer>
                    <PrintCardButton onClick={() => startPrint(prop.img)}>
                      Print
                    </PrintCardButton>
                  </FlexContainer>
                </div>
              )}
              <PropImages src={prop.img} alt={`char${index}`} />

              <InnerPropContainer>
                {prop.optional ? (
                  <p style={{ color: "#808080", padding: 0, margin: 0 }}>
                    (Optional)
                  </p>
                ) : (
                  <p style={{ padding: 0, margin: 0, fontWeight: "bold" }}>
                    Required
                  </p>
                )}
                <h2 style={{ textAlign: "center", margin: 5 }}>{prop.name}</h2>

                {prop.purpose && (
                  <p style={{ textAlign: "center", padding: 0, margin: 0 }}>
                    <h4 style={{ margin: 0, padding: 0 }}>Purpose</h4>
                    <p style={{ textAlign: "center", margin: 5 }}>
                      {prop.purpose}
                    </p>
                  </p>
                )}

                <div style={{ textAlign: "center", margin: 5 }}>
                  <h4 style={{ margin: 0, padding: 0 }}>Description</h4>
                  <p style={{ textAlign: "center", margin: 5 }}>
                    {prop.instruction}
                  </p>
                </div>
                {prop.location && (
                  <div style={{ textAlign: "center", margin: 5 }}>
                    <h4 style={{ margin: 0, padding: 0 }}>
                      Placement location
                    </h4>
                    <p style={{ textAlign: "center", margin: 5 }}>
                      {prop.location}
                    </p>
                  </div>
                )}
              </InnerPropContainer>
            </OuterPropContainer>
          );
        })}
      </PropContainer>
    </Container>
  );
};

const PrintCardButton = styled.button`
  background-color: #ffffff;
  border: 1px solid #000000;
  border-radius: 8px;
  padding: 5px;
  margin: 5px;
  width: 100%;
  cursor: pointer;
  user-select: none;
  &:hover {
    background-color: #000000;
    color: #ffffff;
  }
`;

const Container = styled.div`
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
`;
const PropContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  padding: 5px;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const OuterPropContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 10px;
  width: 250px;
  min-height: 380px;
  @media (max-width: 768px) {
    width: 175px;
    min-height: 225px;
  }
`;

const InnerPropContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;

const PropImages = styled.img`
  width: 200px;
  border-radius: 8px;
  @media (max-width: 768px) {
    width: 150px;
  }
`;
