import styled from "@emotion/styled";
import { ChatCharacters } from "./components/ChatCharacters";
import { useNavigate } from "react-router-dom";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useChatGame } from "./ChatGameContext";
import {
  getChatCharacters,
  getTreasureChatCharacters,
} from "./utils/rss/gamesManager";
import { useAuth0 } from "@auth0/auth0-react";
import { Footer } from "./Footer";
import { TreasureChatCharacters } from "./components/TreasureChatCharacters";

const characters = getTreasureChatCharacters();
console.log(characters);
export const TreasureGame = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate("/");
  };
  const navigateToChat = (id) => {
    // log the id to the console

    // navigate to /game/character with the id in the get params
    navigate(`/bucky/character/${id}`);
  };
  const [guess, setGuess] = useState("");

  const { messages: allMessages } = useChatGame();
  // if allMessages is empty then has chat is false
  const hasChatted = Object.keys(allMessages).length > 0;

  const handleGuessChange = (event) => {
    setGuess(event.target.value);
  };

  const handleGuessSubmit = () => {
    navigate(`/bucky/endgame/${guess}`);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <BackButtonContainer onClick={goBack}>Home</BackButtonContainer>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: 10,
        }}
      >
        <div>
          <h1 style={{ padding: 0, margin: "10px 0", textAlign: "center" }}>
            Solve the Mystery
          </h1>
          <p style={{ textAlign: "center", margin: "0 10px" }}>
            Oh no! The Treasure is lost in Kraken Cove
          </p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TreasureChatCharacters navigateToChat={navigateToChat} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h3 style={{ margin: 0, padding: 0, marginTop: 15 }}>
          why does no one like
        </h3>
        {!hasChatted && (
          <Typography
            variant="caption"
            component="div"
            sx={{ color: "#555555", marginTop: 1 }}
          >
            Chat with a character before submitting a guess!
          </Typography>
        )}
        <Box
          component="form"
          sx={{
            width: "300px",
            display: "flex",
            justifyContent: "center",
            padding: 2,
          }}
          onSubmit={(e) => {
            e.preventDefault();
            handleGuessSubmit();
          }}
        >
          <FormControl variant="outlined" sx={{ width: "95%", marginRight: 1 }}>
            <InputLabel id="guess-label">Enter your guess</InputLabel>
            <Select
              labelId="guess-label"
              id="guess-select"
              value={guess}
              onChange={handleGuessChange}
              label="Enter your guess"
            >
              {characters.map((character) => (
                <MenuItem value={character.id} key={character.id}>
                  {character.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <GuessButton type="submit" disabled={!hasChatted}>
            Guess
          </GuessButton>
        </Box>
      </div>
      <Footer />
    </div>
  );
};

const BackButtonContainer = styled.div`
  background-color: #ffffff;
  border: 1px solid #000000;
  border-radius: 8px;
  padding: 5px;
  margin: 15px;
  min-width: 75px;
  cursor: pointer;
  user-select: none;
  text-align: center;
  &:hover {
    background-color: #000000;
    color: #ffffff;
  }
`;

const GuessButton = styled.button`
  background-color: #ffffff;
  color: black;
  border: 1px solid #000000;
  border-radius: 8px;
  padding: 5px;
  margin: 5px;
  width: 200px;
  cursor: pointer;
  user-select: none;
  &:hover {
    background-color: ${({ disabled }) => (disabled ? "#ffffff" : "#000000")};
    color: ${({ disabled }) => (disabled ? "#000000" : "#ffffff")};
  }
  &:disabled {
    background-color: #f0f0f0;
    border-color: #cccccc;
    color: #999999;
    cursor: not-allowed;
  }
`;
