import styled from "@emotion/styled";
import { SplitContainer, SplitLeft, SplitRight } from "./StyledComponents";

export const Instructions = () => {
  return (
    <div style={{ width: "100%" }}>
      <SplitContainer style={{ gridTemplateColumns: "2fr 1fr" }}>
        <SplitLeft
          style={{
            color: "black",
            padding: "20px 40px",
            backgroundColor: "rgb(249, 249, 249)",
            alignItems: "flex-start",
          }}
        >
          <h1 style={{ margin: 0, padding: 0, textAlign: "left" }}>
            Instructions for Game Host
          </h1>
          <InstructionText>
            <strong>1.</strong> Choose the location for the game
          </InstructionText>

          <InstructionText>
            <strong>2.</strong> Decide on the number of players who will be
            playing
          </InstructionText>
          <InstructionSubText>
            The game is setup to allow for between 9-12 players. Look through
            the character cards, you will notice a few characters are optional.
            You may leave out the optional characters.
          </InstructionSubText>

          <InstructionText>
            <strong>3.</strong> Share a character card with each player
          </InstructionText>
          <InstructionSubText>
            Each card has a "Share" button, click the button and share the URL /
            Link by text message or email. Each character card includes the
            instructions for the players about how to play and win.{" "}
          </InstructionSubText>
          <InstructionText>
            <strong>4.</strong> Print off the props and place them around
          </InstructionText>
          <InstructionSubText>
            The game props can be found at the bottom of this page. Once you
            print them, you can place them around the game location. Feel free
            to get creative. Also if you'd like to get live props instead of
            print offs, that is a welcomed and fun idea as well.
          </InstructionSubText>

          <InstructionText>
            <strong>5.</strong> Check with the group if there are questions
          </InstructionText>
          <InstructionSubText>
            In the character cards you sent each player there are instructions
            on how to win, but it is a good idea to check with the group if
            there are questions. If the villian (Tex Ace) has questions, you'll
            want to be sure to answer those separately to not give away any
            clues to other players.
          </InstructionSubText>
          <InstructionText>
            <strong>6.</strong> Start the game!
          </InstructionText>
          <InstructionSubText>
            We recommend the game is played over one and a half hours, but you
            may adjust it to be shorter or longer depending on the group.{" "}
            <b>Important:</b> In the first 10 minutes of the game, have someone
            Silver Marshal act out that the Bucky the horse is missing.
          </InstructionSubText>
          <InstructionText>
            <strong>7.</strong> As <b>Professor Fate</b>, you may answer one
            "yes" or "no" question
          </InstructionText>
          <InstructionSubText>
            The host takes the <b>Professor Fate</b> role. Each player may ask
            you one yes or no question. If you prefer you can leave this part
            out of the game out, but it adds a fun twist and allows you to help
            if the players are struggling to find the villian, motive, and
            method.
          </InstructionSubText>
          <InstructionText>
            <strong>8.</strong> At the end of the game, collect the
            participants' answers and read them all
          </InstructionText>
          <InstructionSubText>
            If someone is able to correctly guess the who the villian is, how
            they did it, and why they did it then the innocent players win and
            the villian loses. If not then the villian wins. If the villian
            wins, then they or you can reveal the mystery answers.
          </InstructionSubText>
          <br />
          <InstructionSubText>
            <b>
              Correct answer to who stole Bucky the horse is below. It may also
              help to read Tex Ace character card to learn more.
            </b>
            <br />- <i>Who stole Bucky:</i> Tex Ace
            <br />- <i>How did they do it: (Xylazine) Horse tranquilizer </i>
            <br />-{" "}
            <i>
              Why: Money, to sell the horse! (Not needed to be said, but he
              wanted to buy a ranch in another town)
            </i>
          </InstructionSubText>
          <InstructionText>
            <strong>9.</strong> Get into character and Have fun!
          </InstructionText>
          <InstructionSubText>
            Though you are the game host, you are also a character in the game
            and you should have fun! We think the host character is just a fun
            because you get to observer the players and see how they interact
            with each other and the game props.
          </InstructionSubText>
        </SplitLeft>
        <SplitRight style={{ backgroundColor: "rgb(236, 236, 236)" }}>
          <h1 style={{ padding: 0, margin: "10px 0", textAlign: "center" }}>
            The Mystery
          </h1>
          <p>Who stole Bucky the horse!!</p>
          <h1 style={{ padding: 0, margin: "10px 0", textAlign: "center" }}>
            What you'll need
          </h1>
          <p style={{ margin: "10px" }}>9-12 Players including yourself</p>
          <p style={{ margin: "10px" }}>A printer</p>
          <p style={{ margin: "10px" }}>A pen or pencil</p>
          <p style={{ margin: "10px" }}>To read the instructions</p>
        </SplitRight>
      </SplitContainer>
    </div>
  );
};

const InstructionText = styled.p`
  font-size: 18px;
  text-align: left;
`;
const InstructionSubText = styled.p`
  font-size: 14px;
  margin: 0;
  text-align: left;
  padding: 0;
  margin-left: 20px;
`;

const RowContainer = styled.div`
  background-color: black;

  color: white;
  display: flex;
  flex-direction: column;
  padding: 50px;
`;
