import styled from "@emotion/styled";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";

export const CharacterDetail = ({ character, admin }) => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/game?admin=true");
  };

  const [characterInfo, setCharacterInfo] = useState(character);

  return (
    <Container>
      {admin && (
        <BackButtonContainer onClick={goBack}>
          <BackButton />
          <BackText>Back</BackText>
        </BackButtonContainer>
      )}

      <InnerContainer>
        <OverviewContainer>
          <HeaderContainer>
            <h4 style={{ margin: 0, padding: 0 }}>Your Character</h4>
            <Header>{characterInfo.name[0]}</Header>
            <FlexContainer
              style={{
                justifyContent: "space-around",
              }}
            >
              {characterInfo?.antagonist ? (
                <CharacterGuiltDetail style={{ color: "#814141" }}>
                  Villian
                </CharacterGuiltDetail>
              ) : (
                <CharacterGuiltDetail style={{ color: "#0000be" }}>
                  Innocent
                </CharacterGuiltDetail>
              )}

              <ChangeCircleOutlinedIcon
                style={{ cursor: "pointer", padding: 0, margin: 0 }}
                onClick={() => {
                  setCharacterInfo((prevCharacterInfo) => {
                    const updatedCharacterInfo = { ...prevCharacterInfo };

                    updatedCharacterInfo.activeImg =
                      updatedCharacterInfo.activeImg === 0 ? 1 : 0;
                    return updatedCharacterInfo;
                  });
                }}
              />
            </FlexContainer>

            <CharacterImg
              src={characterInfo.img[characterInfo.activeImg]}
              alt={characterInfo.name[0]}
              onClick={() => {
                setCharacterInfo((prevCharacterInfo) => {
                  const updatedCharacterInfo = { ...prevCharacterInfo };

                  updatedCharacterInfo.activeImg =
                    updatedCharacterInfo.activeImg === 0 ? 1 : 0;
                  return updatedCharacterInfo;
                });
              }}
            />
            <SubHeader>{characterInfo.description}</SubHeader>
          </HeaderContainer>

          <InformationContainer id="info-container">
            {characterInfo.shareable && (
              <Information>
                <InformationHeader>Info you’re free to share</InformationHeader>

                <div>
                  {characterInfo.shareable.map((info, id) => {
                    return <SubText key={id}>{info}</SubText>;
                  })}
                </div>
              </Information>
            )}

            {characterInfo.trust && (
              <Information>
                {characterInfo?.antagonist ? (
                  <InformationHeader style={{ color: "#814141" }}>
                    DON'T TELL ANYONE
                  </InformationHeader>
                ) : (
                  <InformationHeader>
                    Be careful with who you share this info with
                  </InformationHeader>
                )}

                <div>
                  {characterInfo?.trust?.map((info, id) => {
                    return <SubText key={id}>{info}</SubText>;
                  })}
                </div>
              </Information>
            )}
            <Information>
              <InformationHeader>How to Win</InformationHeader>

              {characterInfo?.antagonist ? (
                <SubText>
                  You win if you don't get caught and the innocent players can't
                  determine the who, how, and why of the mystery.{" "}
                </SubText>
              ) : (
                <>
                  {characterInfo?.host ? (
                    <div>
                      <SubText>
                        <b>IMPORTANT</b>
                      </SubText>
                      <SubText>
                        As a game host you are not participating in solving the
                        mystery and <b>you can not win.</b>
                      </SubText>
                      <SubText>
                        You already know the characters details and the mystery
                        details. You will still get into character and have fun!
                      </SubText>
                    </div>
                  ) : (
                    <div>
                      <SubText>
                        <b>Solve the mystery</b>
                      </SubText>
                      <SubText>
                        <i>
                          Bucky, the once prized stallion from Rusty Spur Ranch
                          was stolen from the ranch.
                        </i>
                      </SubText>
                      <SubText>- Who stole him</SubText>
                      <SubText>- How </SubText>
                      <SubText>- Why </SubText>
                      <SubText>
                        The host will give you a paper to fill out. Once you
                        feel like you have the answer. Fill it out and provide
                        it to the host. The answers will be read at the end of
                        the game.{" "}
                      </SubText>
                    </div>
                  )}
                </>
              )}
            </Information>
          </InformationContainer>
        </OverviewContainer>
      </InnerContainer>
    </Container>
  );
};

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const CharacterGuiltDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  gap: 20px;
`;

const InformationHeader = styled.h5`
  margin: 0 5px;
  padding: 0;
  line-height: 1.2;
`;

const BackButton = styled(ArrowBackIosIcon)`
  color: gray;
  font-size: 24px;
`;

const BackText = styled.div`
  color: gray;
  font-size: 18px;
`;

// resize on hover
const BackButtonContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  color: gray;
  display: flex;

  margin: 30px;
  cursor: pointer;
  user-select: none;
  @media (max-width: 768px) {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
  }
`;

const Container = styled.div`
  min-height: 100vh;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background-color: #ffffff;
  color: black;
`;

const OverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 5px;
`;

const Header = styled.h1`
  font-size: 24px;
  margin: 0;
  padding: 0;
`;

const SubHeader = styled.h2`
  font-size: 18px;
`;

const CharacterImg = styled.img`
  height: 400px;
  border-radius: 16px;
  @media (max-width: 768px) {
    height: 300px;
  }
`;

const SubText = styled.p`
  font-size: 14px;
  padding: 0;
  margin: 0 5px 5px 5px;
`;

const Information = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  max-width: 400px;
  background-color: #ececec;
  border-radius: 16px;
  padding: 20px;
`;
