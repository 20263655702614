import React, { useState } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useAuth0 } from "@auth0/auth0-react";

export const SupportPage = () => {
  const navigate = useNavigate();
  const [ticketText, setTicketText] = useState("");
  const { getAccessTokenSilently, user } = useAuth0();
  const [submitStatus, setSubmitStatus] = useState("");

  const goBack = () => {
    navigate("/");
  };

  const url = "https://mysteries-backend.herokuapp.com";
  // const url = "http://127.0.0.1:5000";

  const sendSupportTicket = async () => {
    try {
      const token = await getAccessTokenSilently();

      const response = await fetch(url + "/send_support_email", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Set the content type to JSON
        },
        body: JSON.stringify({
          userEmail: user?.email, // Pass the user email here if required
          supportRequest: ticketText, // Pass the support ticket text here
        }),
      });

      if (response.ok) {
        setSubmitStatus("success");
      } else {
        setSubmitStatus("failure");
      }

      const data = await response.text();
      console.log("data", data);
    } catch (error) {
      console.error("Error:", error);
      setSubmitStatus("failure");
    }
  };

  const handleSubmit = async () => {
    // Handle ticket submission logic here
    console.log("Submitted ticket:", ticketText);
    await sendSupportTicket();
  };

  const MAX_CHARACTER_LIMIT = 300;
  const remainingCharacters = MAX_CHARACTER_LIMIT - ticketText.length;

  const isMobile = window.innerWidth < 600;

  return (
    <Container>
      <Header>
        <BackButtonContainer onClick={goBack}>Home</BackButtonContainer>
      </Header>

      <Content>
        <div style={{ width: isMobile ? "300px" : "600px" }}>
          <h2>Submit a support ticket</h2>

          {submitStatus !== "success" && (
            <div>
              <p>
                Having an issue or would like to share feedback. Please let us
                know below.
              </p>
              <TextField
                label="Support Ticket"
                multiline
                rows={4}
                value={ticketText}
                onChange={(e) => setTicketText(e.target.value)}
                variant="outlined"
                fullWidth
                inputProps={{
                  maxLength: MAX_CHARACTER_LIMIT,
                }}
              />
              <CharacterLimit>
                {remainingCharacters}/{MAX_CHARACTER_LIMIT} characters remaining
              </CharacterLimit>
              <SubmitButton variant="contained" onClick={handleSubmit}>
                Submit
              </SubmitButton>
            </div>
          )}

          {submitStatus === "success" && (
            <Typography variant="body1" color="green">
              Ticket submitted successfully!
            </Typography>
          )}
          {submitStatus === "failure" && (
            <Typography variant="body1" color="red">
              Failed to submit ticket. Please try again.
            </Typography>
          )}
        </div>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  height: 100vh;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
`;

const BackButtonContainer = styled.div`
  background-color: #ffffff;
  border: 1px solid #000000;
  border-radius: 8px;
  padding: 5px;
  margin: 5px;
  min-width: 75px;
  cursor: pointer;
  user-select: none;
  text-align: center;
  color: #000000;
  &:hover {
    background-color: #000000;
    color: #ffffff;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  height: 400px;
`;

const CharacterLimit = styled(Typography)`
  margin-top: 10px;
  font-size: 12px;
  text-align: right;
  color: ${(props) => (props.remaining < 0 ? "#f44336" : "inherit")};
`;

const SubmitButton = styled(Button)`
  margin-top: 20px;
  background-color: #1e6174;
  color: #ffffff;
  &:hover {
    background-color: #15505e;
  }
`;
