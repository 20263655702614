import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { GameOptions } from "./GameOptions";
import { LandingDetail } from "./LandingDetail";
import { useNavigate } from "react-router-dom";
import { Footer } from "./Footer";

export const Landing = () => {
  const [buckyEntered, setBuckyEntered] = useState(false);
  const [treasureEntered, setTreasureEntered] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (buckyEntered) {
      navigate("/bucky");
    }
  }, [buckyEntered]);

  useEffect(() => {
    if (treasureEntered) {
      navigate("/treasure");
    }
  }, [treasureEntered]);

  return (
    <div>
      <GameOptions
        setBuckyEntered={setBuckyEntered}
        setTreasureEntered={setTreasureEntered}
      />

    </div>
  );
};
