import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import KeyFourImage from "./images/key-4.jpeg";
import { getChatGameImgs } from "./utils/rss/gamesManager";
import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material/";
import StarIcon from "@mui/icons-material/Star";
import GamepadIcon from "@mui/icons-material/Gamepad";
import LandscapeIcon from "@mui/icons-material/Landscape";
import GroupIcon from "@mui/icons-material/Group";
import TuneIcon from "@mui/icons-material/Tune";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { useAuth0 } from "@auth0/auth0-react";

const gameImages = getChatGameImgs();

const TEST = false;

export const TreasurePreorder = () => {
  const navigate = useNavigate();

  const {
    user,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();

  const urlParams = new URLSearchParams(window.location.search);
  const sessionId = urlParams.get("session_id");

  const [checkoutCompleted, setCheckoutCompleted] = useState(false);

  const verifyAndSendGameCode = async () => {
    try {
      const token = await getAccessTokenSilently();

      const email = user?.email;

      const url = TEST ? test_url : prod_url;

      const response = await fetch(url + "/verify-payment", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Set the content type to JSON
        },
        body: JSON.stringify({
          userEmail: email,
          sessionId: sessionId,
        }),
      });
      console.log("response", response);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (sessionId && user) {
      setCheckoutCompleted(true);
      verifyAndSendGameCode();
    }
  }, [sessionId, user]);

  const goBack = () => {
    navigate("/");
  };

  // List of new features for the game
  const features = [
    { text: "No more message caps", icon: <ChatBubbleOutlineIcon /> },

    { text: "Clue items to be discovered", icon: <StarIcon /> },
    { text: "New action capabilities", icon: <LandscapeIcon /> },
    {
      text: "More immersive characters and full game experience",
      icon: <GamepadIcon />,
    },
    {
      text: "New single interface for chatting with character",
      icon: <GroupIcon />,
    },

    // Add as many new features as you'd like
  ];

  const prod_url = "https://mysteries-backend.herokuapp.com";
  const test_url = "http://127.0.0.1:5000";

  const preOrderClick = async () => {
    try {
      const token = await getAccessTokenSilently();

      const url = TEST ? test_url : prod_url;

      const response = await fetch(url + "/create_checkout_session", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Set the content type to JSON
        },
        body: JSON.stringify({
          test: TEST,
        }),
      });

      const session = await response.json();
      console.log("session", session);

      window.location.href = session.url;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const clickedLogin = () => {
    loginWithRedirect();
  };

  const isMobile = window.innerWidth < 768;

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          width: "100%",
          backgroundColor: "#1e6174",
        }}
      >
        <BackButtonContainer onClick={goBack}>Home</BackButtonContainer>
      </div>
      <Container>
        {isMobile && (
          <RightSplit>
            <img
              src={gameImages.treasure}
              alt="key"
              style={{ width: "200px", borderRadius: "40px", margin: 20 }}
            />
          </RightSplit>
        )}
        <LeftSplit>
          <FormContainer>
            <h1 style={{ margin: 0, padding: 0, textAlign: "left" }}>
              The Lost Treasure of Kraken Cove
            </h1>
            <p style={{ margin: "10px 0", padding: 0, textAlign: "left" }}>
              Expected arrival date of June 2023
            </p>

            {!checkoutCompleted && (
              <div>
                {" "}
                <h4
                  style={{
                    margin: "10px 0 0 0",
                    padding: 0,
                    textAlign: "left",
                  }}
                >
                  What's new!
                </h4>
                <List>
                  {features.map((feature, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>{feature.icon}</ListItemIcon>
                      <ListItemText primary={feature.text} />
                    </ListItem>
                  ))}
                </List>
                <h3 style={{ margin: "10px 0", padding: 0, textAlign: "left" }}>
                  Preorder now for $3
                </h3>
              </div>
            )}
          </FormContainer>
          <div>
            {!checkoutCompleted ? (
              <div>
                {!isAuthenticated ? (
                  <div>
                    <div style={{ marginTop: 15 }}>
                      You need to be logged in to preorder
                    </div>
                    <CallToActionButton onClick={clickedLogin}>
                      LOGIN OR SIGNUP
                    </CallToActionButton>
                  </div>
                ) : (
                  <div>
                    <CallToActionButton onClick={preOrderClick}>
                      PREORDER
                    </CallToActionButton>
                    <div style={{ marginBottom: 25 }}>
                      Payment processed securely by Stripe
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div style={{ margin: 25 }}>
                <h2>You've completed your preorder!</h2>
                <p>Look for an email with the access code</p>
              </div>
            )}
          </div>
        </LeftSplit>
        {!isMobile && (
          <RightSplit>
            <img
              src={gameImages.treasure}
              alt="key"
              style={{ width: "300px", borderRadius: "40px", margin: 20 }}
            />
          </RightSplit>
        )}
      </Container>
    </div>
  );
};

const Container = styled.div`
  min-height: 100vh;
  display: grid;
  grid-template-columns: 3fr 2fr;
  align-items: center;
  justify-content: center;
  padding: 0 20px;

  background-color: #1e6174;
  color: white;
  text-align: center;
  overflow: hidden;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const CallToActionButton = styled.button`
  cursor: pointer;
  margin: 25px;
  font-size: 35px;
  padding: 10px 40px;
  background-color: #ffffff;
  color: black;
  border-radius: 15px;
  user-select: none;
  border: none;
  &:hover {
    background-color: black;
    color: white;
  }
  &:active {
    scale: 1.1;
  }
`;

const LeftSplit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const RightSplit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: #ffffff;
  color: black;
  text-align: center;
  overflow: hidden;
  border-radius: 10px;
  width: 500px;
  padding: 20px;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const BackButtonContainer = styled.div`
  background-color: #ffffff;
  border: 1px solid #000000;
  border-radius: 8px;
  padding: 5px;
  margin: 15px;
  min-width: 75px;
  cursor: pointer;
  user-select: none;
  text-align: center;
  &:hover {
    background-color: #000000;
    color: #ffffff;
  }
`;
