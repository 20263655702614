import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
} from "react-router-dom";

import "./App.css";
import { Game } from "./Game";
import { CharacterPage } from "./CharacterPage";
import { Landing } from "./Landing";

import { Payments } from "./Payments";
import { BuckyGame } from "./BuckyGame";
import { BuckyPage } from "./BuckyPage";
import { ChatGameProvider } from "./ChatGameContext";
import { EndGame } from "./EndGame";
import { LandingDetail } from "./LandingDetail";
import { TreasurePreorder } from "./TeasurePreorder";
import { SupportPage } from "./SupportPage";
import { TreasureGame } from "./TreasureGame";

const App = () => {
  return (
    <Router>
      <ChatGameProvider>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/payments" element={<Payments />} />
          <Route path="/game" element={<Game />} />
          <Route path="/archive" element={<LandingDetail />} />
          <Route
            path="/game/character/:id/:admin?"
            element={<CharacterPage />}
          />
          <Route path="/bucky" element={<BuckyGame />} />
          <Route path="/bucky/character/:id" element={<BuckyPage />} />
          <Route path="/bucky/endgame/:id" element={<EndGame />} />
          <Route path="/treasure" element={<TreasurePreorder />} />
          <Route path="/treasure/play" element={<TreasureGame />} />
          <Route path="/support" element={<SupportPage />} />
        </Routes>
      </ChatGameProvider>
    </Router>
  );
};

export default App;
