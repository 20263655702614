import styled from "@emotion/styled";
import { Characters } from "./components/Characters";
import { Instructions } from "./components/Instructions";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { GameProps } from "./components/GameProps";
import { useEffect, useState } from "react";
import KeyFourImage from "./images/key-4.jpeg";

export const Game = ({ game, setGame }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // if admin=true in url then setPassCode(true)
    const urlParams = new URLSearchParams(window.location.search);
    const admin = urlParams.get("admin");
    if (admin === "true") {
      setPassedCode(true);
    }
  }, []);

  const navigateToCharacter = (id) => {
    // navigate to /game/character with the id in the get params
    navigate(`/game/character/${id}/admin`);
  };

  const [code, setCode] = useState("");
  const [showValidationError, setShowValidationError] = useState(false);
  const [passedCode, setPassedCode] = useState(false);

  const goToGameAssets = () => {
    console.log("go to game assets");
    const c = code.toLowerCase();
    if (c === "ai-games") {
      // redirect to url with &admin=true

      navigate(`/game?admin=true`);
      // fire a use effect []
      setPassedCode(true);
    } else {
      setShowValidationError(true);
      setTimeout(() => {
        setShowValidationError(false);
      }, 3000);
    }
  };

  return (
    <Container>
      {passedCode && (
        <div>
          <Instructions />
          <CharacterContainer>
            <h1>Characters</h1>
            <Characters navigateToCharacter={navigateToCharacter} />
          </CharacterContainer>
          <GameProps />
        </div>
      )}

      {!passedCode && (
        <div>
          <LeftSplit>
            <FormContainer>
              <h2>Enter in your code</h2>

              <TextField
                id="outlined-basic"
                label="Access Code"
                variant="outlined"
                onChange={(e) => {
                  setCode(e.target.value);
                }}
                value={code}
              />
              <CallToActionButton onClick={goToGameAssets}>
                GET THE GAME
              </CallToActionButton>
            </FormContainer>
          </LeftSplit>
          <RightSplit>
            <img
              src={KeyFourImage}
              alt="key"
              style={{ width: "300px", borderRadius: "40px", margin: 20 }}
            />
          </RightSplit>
        </div>
      )}
    </Container>
  );
};
const CharacterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  background-color: #ffffff;

  color: black;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const LeftSplit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const RightSplit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: black;
  text-align: center;
  overflow: hidden;
  border-radius: 10px;
  width: 500px;
  padding: 20px;

  @media (max-width: 768px) {
    width: 400px;
  }
`;

const CallToActionButton = styled.button`
  cursor: pointer;
  margin: 15px;
  font-size: 35px;
  padding: 10px 40px;
  background-color: #ececec;
  color: black;
  border-radius: 15px;
  user-select: none;
  border: none;
  &:hover {
    background-color: black;
    color: white;
  }
  &:active {
    scale: 1.1;
  }
`;
